<template>
<div
    class="knowMore"
    v-tooltip.top-center="{
        content: label,
        delay: {
        show: 0.3,
        hide: 0,
        }
    }"
>
    ?
</div>
</template>

<script>
export default {
  name: 'knowMore',
  components: {
  },
  props: {
    label: String,
  },
  computed: {
  },
  data() {
    return {
      selectedForm: 'global',
    };
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  },
  beforeDestroy() {
  },

};
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/_variables.scss";
    .knowMore{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1vh;
        color: #ffffff;
        background-color: $border_darkgray;
        height: 1.5vh;
        width: 1.5vh;
        border-radius: 50%;
        border: 1px solid $border_darkgray;
        margin-left: 2vh;
        cursor: pointer;
        transition: all .3s;
        &:hover{
            background-color: #ffffff;
            color: $border_darkgray;
        }
    }
</style>
