<template>
<div
  class="job-form flex-container flex-full no-padding no-margin"
  :class="!this.selected?'no-selected-main':''"
>
  <div
    class="inner-job-form elem-flex mhspe flex-overflow-column"
    v-if="!loading && (selected || this.new)"
  >
    <div class="subtitle-page wother">
      {{offer.name || '&nbsp;'}}
      <div class="other">
        <div
          v-if="!sending"
          class="btn-green radius-small small"
          @click="cancel"
        >
         Cancel
        </div>
        <div
          v-if="!sending"
          class="btn-green radius-small small"
          @click="submit"
        >
          Submit
        </div>
        <div v-else>
          Sending ...
        </div>
        <!-- <div
          class="btn-green radius small reverse"
          @click="goPreview"
        >
          Preview
        </div> -->

      </div>
    </div>
    <div class="select-form">
      <div id="global-setting" class="option" @click="() => openPanel('global')">
        Global Settings
        <div class="underline" v-if="selectedForm === 'global'" />
      </div>
      <div id="offer-setting" class="option" @click="() => openPanel('offer')">
        Offer Settings
        <div class="underline" v-if="selectedForm === 'offer'" />

      </div>
      <div id="salary-setting" class="option" @click="() => openPanel('salary')">
        Salary Settings
        <div class="underline" v-if="selectedForm === 'salary'" />

      </div>
      <div id="options-setting" class="option" @click="() => openPanel('options')">
        Options
        <div class="underline" v-if="selectedForm === 'options'" />

      </div>
    </div>
    <div id="listform" class="elem-flex  flex-overflow-column" ref="scroll">
      <div class="block-settings open" v-show="selectedForm === 'global'" section="global">

        <div class="cont-form" >
          <div class="elem-form">
            <label>Job's Title</label>
            <input class="v-select" v-model="offer.name" requiredVal="offer.name" />
          </div>
          <div class="elem-form">
            <label>Job's Description</label>
            <wysiwyg class="wysiwyg radius" v-model="offer.description"
              requiredVal="offer.description" />
          </div>
          <div class="cont-half">
            <div class="elem-form">
              <label>Field</label>
              <v-select
                :options="sectors"
                v-model="offer.sector"
                label="name"
                class="no-remove"
                placeholder="Sector"
                requiredVal="offer.sector"
              />
            </div>
            <div class="elem-form">
              <label>Location</label>
              <input class="v-select" v-model="offer.location" requiredVal="offer.location" />
            </div>
          </div>
          <div class="elem-form">
            <label>Profile Description</label>
            <wysiwyg class="wysiwyg radius" v-model="offer.desiredProfileDescription"
            requiredVal="offer.desiredProfileDescription" />
          </div>
          <div class="cont-half">
            <div class="elem-form">
              <label>Education</label>
              <v-select
                :options="studyLevel"
                placeholder="Study level"
                v-model="offer.desiredLevelOfStudy"
                label="name"
              />
            </div>
            <div class="elem-form">
              <label>Year(s) of Experience</label>
              <!-- <input class="v-select" type="number" v-model="offer.expectedExperience" /> -->
              <inputNumber
                v-model="offer.expectedExperience"
                :data="parseInt(offer.expectedExperience)"
                :min="1"
                :max="50"
                :step="1"
              ></inputNumber>
            </div>
          </div>
        </div>

      </div>
      <div class="block-settings  open" v-show="selectedForm === 'offer'" section="offer">

        <div class="cont-form" >
          <div class="cont-half">
            <div class="elem-form">
              <label>Start Date</label>
              <!-- <input class="v-select" v-model="offer.startDate" /> INPUT DATE PICKER -->
              <datepicker class="radius" :value="offer.startDate"
                @selected="setDate" requiredVal="offer.startDate"
                :disabledDates="{to: new Date(Date.now() - 86400000) }"/>
            </div>
            <div class="elem-form">
              <label>Interview Process</label>
              <v-select
                :options="companyProcesses"
                v-model="offer.process"
                placeholder="Company process"
                label="name"
                :reduce="process => process.value"
                requiredVal="offer.process"
                class="no-remove"
              />
            </div>
          </div>
          <div class="elem-form">
            <label>Job Type</label>
            <selectSwitch
              :dataSwitch="contracts"
              v-model="offer.contract"
              requiredVal="offer.contract"
            />
          </div>
          <div class="elem-form" v-if="offer.remote !== 'Partial'">
            <label>Remote Work</label>
            <selectSwitch
              :dataSwitch="remote"
              v-model="offer.remote"
            />
          </div>
          <div class="cont-half" v-if="offer.remote === 'Partial'">
            <div class="elem-form">
              <label>Teleworking?</label>
              <selectSwitch
                class="tier full"
                :dataSwitch="remote"
                v-model="offer.remote"
              />
            </div>
            <div class="elem-form">
              <label>Frequency</label>
              <v-select
                :options="remoteFrequency"
                v-model="offer.remoteFrequency"
                placeholder="Renote frequency"
                label="frequency"
              />
            </div>
          </div>
          <div class="elem-form">
            <label>Display Number of Application</label>
            <selectSwitch
              :dataSwitch="boolTrueFalse"
              v-model="offer.displayApplicantNumber"
            />
          </div>
          <div class="elem-form">
            <label>Criteria</label>
            <div class="cont-edit-bubble">
              <bubble-selector
              v-for="(entry, index) in offer.criteriaList"
              v-model="offer.criteriaList[index]"
              :key="entry.name"
              :delete="() => {offer.criteriaList.splice(index, 1)}"/>
              <div
                class="btn-green plus"
                v-if="offer.criteriaList.length <5"
                @click="() => {offer.criteriaList.push({})}">
                +
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block-settings  open" v-show="selectedForm === 'salary'" section="salary">
        <div class="cont-form" >
          <div class="elem-form">
            <label>Salary Frequency</label>
            <selectSwitch
              :dataSwitch="salaryFrequency"
              v-model="offer.salary.frequency"
            />
          </div>
          <div class="cont-half">
            <div class="elem-form">
              <label>Salary Currency</label>
              <selectSwitch
                class="full tier"
                :dataSwitch="salaryCurrency"
                v-model="offer.salary.currency"
              />
            </div>
            <div class="elem-form">
              <label>Salary Info</label>
              <!-- {{salaryRangeData}} -->
              <selectSwitch
                class="full half"
                :dataSwitch="salaryRange"
                v-model="offer.salary.salaryType"
              />
            </div>
          </div>
          <div class="elem-form" v-if="offer.salary.salaryType === 'Number'">
            <label>Salary Amount</label>
            <div class="euro" v-if="offer.salary.currency === '€'">
              <inputNumber
                v-model="offer.salary.amount"
                :data="offer.salary.amount"
                :step="100"
                :min="0"
                :max="0"
              ></inputNumber>
            </div>
            <div class="dollar" v-if="offer.salary.currency === '$'">
              <input
                step="100"
                class="v-select "
                type="number"
                v-model="offer.salary.amount"
              />
            </div>
            <div class="livre" v-if="offer.salary.currency === '£'">
              <input
                step="100"
                class="v-select "
                type="number"
                v-model="offer.salary.amount"
              />
            </div>
            <!-- {{offer.salary.currency}} -->
            <div class="livre" v-if="offer.salary.currency === ''">
              Please Select a Currency Type
            </div>
          </div>
          <div class="elem-form" v-if="offer.salary.salaryType === 'Range'">
            <label>Salary Range</label>
            <div>
              <inputRange
                :dataRangeMin="salaryRangeValue[0]"
                :dataRangeMax="salaryRangeValue[1]"
                :step="1000"
                v-model="offer.salary.range"
              />
              min : {{offer.salary.range.min}} {{offer.salary.currency}}
              max : {{offer.salary.range.max}} {{offer.salary.currency}}
            </div>
          </div>
        </div>
      </div>
      <div class="block-settings  open" v-show="selectedForm === 'options'" section="options">

        <div class="cont-form" >
          <div class="elem-form">
            <label>Audience <knowMore :label="'Where the offer should be visible'"/></label>
            <selectSwitch
              :dataSwitch="diffusion"
              v-model="offer.diffusion"
            />
          </div>
          <div class="elem-form">
            <label>Cover Letter <knowMore :label="'temp text'"/></label>
            <selectSwitch
              :dataSwitch="commonValues"
              v-model="offer.applicationRequirements.motivationLetter"
            />
          </div>
          <div class="elem-form">
            <label>Resume (file) <knowMore :label="'temp text'"/></label>
            <selectSwitch
              :dataSwitch="commonValues"
              v-model="offer.applicationRequirements.resumeFile"
            />
          </div>
          <div class="elem-form">
            <label>Resume (from profile) <knowMore :label="'temp text'"/></label>
            <selectSwitch
              :dataSwitch="commonValues"
              v-model="offer.applicationRequirements.resumeProfile"
            />
          </div>
          <div class="elem-form">
            <label>Portfolio (link) <knowMore :label="'temp text'"/></label>
            <selectSwitch
              :dataSwitch="commonValues"
              v-model="offer.applicationRequirements.portfolio"
            />
          </div>
          <div class="elem-form">
            <label>Profile Completion (%) <knowMore :label="'temp text'"/></label>
            <selectSwitch
              :dataSwitch="commonValues"
              v-model="offer.applicationRequirements.profileCompletion.requirement"
            />
          </div>
          <div
            class="elem-form"
            v-if="offer.applicationRequirements.profileCompletion.requirement == 'Mandatory'"
          >
            <label>Percentage of Profile Completion <knowMore :label="'temp text'"/></label>
            <selectSwitch
              :dataSwitch="completionPercentage"
              v-model="offer.applicationRequirements.profileCompletion.percentComplete"
            />
          </div>
          <div class="cont-half">
            <div class="elem-form">
              <label>Working Permit <knowMore :label="'temp text'"/></label>
              <selectSwitch
                :dataSwitch="boolTrueFalse"
                class="full half"
                v-model="offer.applicationRequirements.countryPermission.requirement"
              />
            </div>
            <div
              class="elem-form"
              v-if="offer.applicationRequirements.countryPermission.requirement"
            >
              <label>Country <knowMore :label="'temp text'"/></label>
              <v-select
                :options="country"
                placeholder="Country"
                v-model="offer.applicationRequirements.countryPermission.country"
                label="name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!this.selected && !this.new" class="no-selected">
    <h3>Please select offer to edit</h3>
  </div>
  <loading v-if="this.loading && this.selected" />
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import PerfectScrollbar from 'perfect-scrollbar';
import Loading from '@/components/utilityPartials/_loading.vue';
import inputNumber from '@/components/utilityPartials/inputNumber.vue';
import selectSwitch from '@/components/utilityPartials/selectSwitch.vue';
import inputRange from '@/components/utilityPartials/inputRange.vue';
import knowMore from '@/components/UniversalComponents/Utilities/knowMore.vue';
// import FileUpload from '@/components/utilityPartials/fileUpload.vue';
import validateMixin from '@/modules/mixins/validate';
import BubbleSelector from '@/components/EmployeeComponents/JobManagement/managementPartials/bubbleSelector.vue';

const blankOffer = () => ({
  name: '',
  title: '',
  description: '',
  contract: '',
  location: '',
  remote: 'No',
  remoteFrequency: '',
  sector: '',
  requirements: [],
  desiredProfileDescription: '',
  desiredLevelOfStudy: '',
  expectedExperience: 1,
  projectPresentation: '',
  teamPresentation: '',
  image: '',
  // Query Attributes
  startDate: '',
  salary: {

    frequency: 'Unspecified',
    currency: '',
    amount: 0,
    range: { min: 0, max: 150000 },
    salaryType: 'Range',
  },
  process: '',
  // Process Attributes
  diffusion: 'Platform',
  displayApplicantNumber: false,
  criteriaList: [],

  applicationRequirements: {
    motivationLetter: 'Optional',
    resumeFile: 'Optional',
    resumeProfile: 'Optional',
    portfolio: 'Optional',
    profileCompletion: {
      requirement: 'Optional',
      percentComplete: 100,
    },
    countryPermission: {
      requirement: false,
      country: '',
    },
  },
});
export default {
  name: 'OfferForm',
  components: {
    Loading,
    selectSwitch,
    inputRange,
    inputNumber,
    Datepicker,
    // FileUpload,
    BubbleSelector,
    knowMore,
  },

  mixins: [validateMixin],
  computed: {
    offerId() {
      return this.$store.state.company.currentOfferApplicationId;
    },
    selected() {
      return this.$store.getters['company/selectedOffer'];
    },
    new() {
      return this.$store.state.company.currentOfferApplicationId === 'new';
    },
    companyProcesses() {
      return this.$store.getters['company/processes'].map((process) => ({
        name: process.name,
        value: process._id,
      }));
    },
  },
  data() {
    return {
      selectedForm: 'global',
      loading: true,
      scrollInit: false,
      sending: false,
      edit: {
        image: false,
      },
      error: false,
      // Default Options for Select
      studyLevel: ['Bac', 'Bac +1', 'Bac +2', 'Bac +3', 'Bac +4', 'Bac +5', 'Bac +6', 'Bac +7', 'Bac +8', 'Bac +9', 'Bac 9+'],
      contracts: {
        default: 0,
        data: ['Full-Time', 'Freelance', 'Part-Time', 'Internship'],
      },
      remoteFrequency: ['1 day per week', '2 days per week', '3 days per week', '4 days per week'],
      sectors: ['AI', 'AR/VR', 'BlockChain', 'Data science', 'Cyber security', 'FinTech'],
      remote: {
        default: 1,
        data: ['Yes', 'No', 'Partial'],
      },
      salaryFrequency: {
        default: 0,
        data: ['Annual', 'Monthly', 'Daily', 'Unspecified'],
      },
      salaryCurrency: {
        default: 0,
        data: ['€', '$', '£'],
      },
      salaryRange: {
        default: 0,
        data: ['Number', 'Range'],
      },
      salaryRangeData: '',
      salaryRangeValue: [0, 150000],
      diffusion: {
        default: 0,
        data: ['Public', 'Platform', 'Intern'],
      },
      processValues: {
        data: ['Mandatory', 'Optional', 'Disabled'],
        default: 2,
      },
      commonValues: {
        default: 1,
        data: ['Mandatory', 'Optional', 'Disabled'],
      },
      trueFalse: {
        default: 1,
        data: ['true', 'false'],
      },
      boolTrueFalse: {
        default: 1,
        data: [true, false],
      },
      completionPercentage: {
        default: 0,
        data: [25, 50, 75, 100],
      },
      country: ['France', 'USA', 'England', 'Australia'],
      // Offer option value
      offer: blankOffer(),
    };
  },
  mounted() {
    if (this.selected) {
      this.loadOffer();
    } else {
      this.loading = false;
    }

    setTimeout(() => {
      this.startScroll();
    }, 500);
  },
  methods: {
    openPanel(name) {
      this.selectedForm = name;
    },
    setDate(date) {
      this.offer.startDate = date.toISOString();
    },
    startScroll() {
      const ps = new PerfectScrollbar('#listform', {
        wheelPropagation: false,
        suppressScrollX: true,
      });
      ps.update();
      this.scrollInit = true;
    },

    submit() {
      this.clearValidatedFields();
      if (this.validateFields()) {
        this.error = false;
        this.offer.applicationRequirements.countryPermission.requirement = this.offer.applicationRequirements.countryPermission.requirement === 'true';
        const cb = (id) => {
          this.$store.dispatch('company/setCurrentOfferApplicationId', id);
          this.sending = false;
          this.$store.dispatch('offer/getOffer', id);
          this.$emit('endEdit');
          // if (this.$route.path !== '/myCompany/job-management') {
          // }
        };
        this.sending = true;
        if (this.edit.image) {
          this.$refs.file.upload().then(() => {
            const offerData = this.$api.pruneObject(this.offer);

            if (this.new) {
              this.$store.dispatch('company/addOffer', offerData).then((id) => cb(id));
            } else {
              this.$store.dispatch('company/editOffer', { id: this.offerId, offerData }).then((id) => cb(id));
            }
            this.edit.image = false;
          });
        } else {
          const offerData = this.$api.pruneObject(this.offer);

          if (this.new) {
            this.$store.dispatch('company/addOffer', offerData).then((id) => cb(id));
          } else {
            this.$store.dispatch('company/editOffer', { id: this.offerId, offerData }).then((id) => cb(id));
          }
        }
      } else {
        this.highlightParentSection('block-settings');
        this.$toastError('Offer Couldn\'t be created: Missing Required Fields');
        this.error = true;
      }
    },
    cancel() {
      this.$emit('endEdit');
    },
    loadOffer() {
      this.loading = true;
      this.$api.get(`company/offers/${this.offerId}`).then((res) => {
        this.offer = { ...this.offer, ...res.data };
        this.loading = false;
      });
    },
    onInputDesc(e) {
      this.offer.description = e.target.innerText;
    },
    onInputDProfile(e) {
      this.offer.desiredProfileDescription = e.target.innerText;
    },
    goPreview() {
      const routeData = this.$router.resolve({ name: 'OfferPreview', query: { offer: JSON.stringify(this.offer) } });
      window.open(routeData.href, '_blank');
    },
  },
  watch: {
    offerId(newValue) {
      this.error = false;
      this.scrollInit = false;
      this.offer = blankOffer();
      if (this.companyProcesses.length > 0) {
        this.offer.process = this.companyProcesses[0].value;
      }
      if (newValue !== 'new') {
        this.loadOffer();
      }
      setTimeout(() => {
        this.startScroll();
      }, 500);
    },
    // eslint-disable-next-line func-names
    'offer.salary.salaryType': function (newVal) {
      if (newVal === 'Range' && !this.offer.salary.range) {
        this.offer.salary.range = { min: 0, max: 150000 };
      }
      if (newVal === 'Number' && !this.offer.salary.amount) {
        this.offer.salary.amount = 0;
      }
    },
    selectedForm() {
      document.getElementById('listform').scrollTop = 0;
    },
  },
  beforeDestroy() {
    this.$store.dispatch('company/clearCurrentOfferNew');
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .euro{
    position: relative;
    input{
      padding-left: 2.5vh;
    }
    &:after{
      font-size: $fnt-14;
      color: $text_color;
      content: '€';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%) translateX(75%);
    }
  }

  .dollar{
    position: relative;
    input{
      padding-left: 2.5vh;
    }
    &:after{
      font-size: $fnt-14;
      color: $text_color;
      content: '$';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%) translateX(100%);
    }
  }

  .livre{
    position: relative;
    input{
      padding-left: 2.5vh;
    }
    &:after{
      font-size: $fnt-14;
      color: $text_color;
      content: '£';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%) translateX(100%);
    }
  }
  .error-message{
    font-size: 12px;
    color: $red_color;
  }
  #listform{
    max-height: 43vh;
    position: relative;
    width: 100%;
    overflow: hidden;
    .block-settings{
      width: calc(100% - 1.5vh) !important;
      padding: 0.5vh;
    }
    .cont-half{
    }
  }
    .select-form{
       display: flex;
       width: calc(100% - 2vh);
       padding: 1vh;
       flex-direction: row;
       justify-content: start;
       margin-bottom: 1vh;
        .option{
          font-size: 2vh;
          font-weight: 600;
          margin-right: 1vw;

          cursor: pointer;
          flex: 1;
          position: relative;
          .underline{
            position: absolute;
            height: 4px;
            width: 35%;
            background-color:#8c28e1 ;
            border-radius: 2px;
            margin-top: 1vh;
          }
          &.input-error{
            color: red ;
            border: 0px solid #fff !important;
          }
        }

    }
  .job-form{
    width: 100%;
    height: 100%;
    &.no-selected-main{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .inner-job-form{
      height: 100%;
    }
    .subtitle-page{
      width: calc(100% - 1vh);
      margin: 0vh 0.5vh 1.5vh 0.5vh;
    }
    .block-settings{
      padding: 0;
      width: calc(100% - 1.5vh) !important;
      // width: calc(100% - 2vw) !important;
      .head-settings{
        padding: 2vh 0;
        .title-block{
          padding: 0;
          font-size: $fnt-subtitle;
          font-family: 'MontserratRegular';
        }
      }
      .cont-form{
        .elem-form{
          width: 100%;
          label{
            display: flex;
            align-items: center;
          }
          .v-select{
            border-radius: 1vh;
            border: none;
            box-shadow: 0 0 0.5vh rgba(22, 0, 51, 0.15);
          }
          .editr{
            max-width: 48.5vw;
            border-radius: 1vh;
            border: none;
            box-shadow: 0 0 0.5vh rgba(22, 0, 51, 0.15);
          }
        }
      }
      &.open{
        .cont-form{
          padding-top: 2vh;
          overflow: visible;
          height: auto;
        }
      }
    }
  }
</style>
