<template>
  <div class="elem-bubble" :class="edit ? 'edit' : ''">
    <div class="view bubble" v-if="!edit">
      <div class="radius display-bubble"
        :style="`background-color:${value.color}`"
        v-if="value.color !== '#160033'"
      >
        {{this.value.name}}
        {{this.value.category === 'minExp' ? `Year${this.value.name ===1 ? '': 's'}`: ''}}
      </div>
      <div class="radius display-bubble"
        :style="`background-color:${value.color}; color: #ffffff;`"
        v-else
      >
        {{this.value.name}}
        {{this.value.category === 'minExp' ? `Year${this.value.name ===1 ? '': 's'}`: ''}}
      </div>
      <div class="cont-edit">
        <div class="link" @click="toggleEdit">Edit</div>
        <div class="link delete" @click="deleteThis">Delete</div>
      </div>
    </div>
    <div class="edit bubble" v-if="edit">
      <v-select
          :options="options.category"
          v-model="category"
          label="name"
          class="no-remove category"
          placeholder="Category"
          :reduce="data => data.entry"
        />

      <!--Inpuit-->
      <v-select
        :searchable="true"
        v-model="name"
        :options="entryOptions"
        class="no-remove supp"
        placeholder="Select a technology"
        v-if="category === 'technologies'"
      />
      <input
        class="supp"
        v-model.number="name"
        v-else-if="category === 'minExp'"
        type="number"
        :placeholder="'Number of year'"
      />
      <input
        class="supp"
        v-model="name"
        v-else
        :placeholder="'Type a skill'"
      />
      <!-- <label>Color</label> -->
      <div class="cont-color-selector">
        <div
          class="selectedcolor radius box-shadow"
          v-if="color !== ''"
          :style="`background-color:${color}`"
        ></div>
        <v-select
          :searchable="true"
          v-model="color"
          label="name"
          :options="colorOption"
          placeholder="Color"
          :reduce="color => color.entry"
          class="no-remove color"
        />
      </div>
      <!-- {{color}} -->
      <!-- <input-color-picker v-model="color" /> -->

      <div
        class="link"
        @click="submit"
        :class="this.name && this.category ? '' : 'delete'"
      >
        {{this.name && this.category ? 'Add' : 'Cancel'}}
      </div>
    </div>
  </div>
</template>

<script>
import technoList from '@/components/TalentComponents/talentTechnos/talentTechnoList';
// import InputColorPicker from 'vue-native-color-picker';

export default {
  name: 'BubbleSelector',
  props: ['value', 'delete'],
  components: {
    // InputColorPicker,
  },
  data() {
    return {
      name: '',
      category: '',
      color: '',
      edit: false,
      options: {
        category: [
          { name: 'Skills', entry: 'skills' },
          { name: 'Technologies', entry: 'technologies' },
          { name: 'Minimum Experience', entry: 'minExp' },

        ],
        selectOptions: {
          technologies: technoList.map((t) => t.text),
        },
      },
      colorOption: [
        { name: 'Blue', entry: '#48CEFF' },
        { name: 'Dark Purple', entry: '#160033' },
        { name: 'Green', entry: '#48FFAF' },
        { name: 'Purple', entry: '#8C28E1' },
        { name: 'Red', entry: '#FF4848' },
        { name: 'Yellow', entry: '#FFCE48' },
      ],
    };
  },
  computed: {
    entryOptions() {
      if (!this.category) {
        return [];
      }
      return this.options.selectOptions[this.category];
    },
    selectInput() {
      if (!this.category) {
        return true;
      }
      return !!this.options.selectOptions[this.category];
    },
  },
  methods: {
    toggleEdit() {
      this.edit = !this.edit;
    },
    deleteThis() {
      this.delete();
    },
    submit() {
      if (!(this.name && this.category)) {
        if (!(this.value.name)) {
          this.deleteThis();
        } else {
          this.name = this.value.name;
          this.category = this.value.category;
          this.color = this.value.color;
        }
      } else {
        this.$emit('input', { name: this.name, category: this.category, color: this.color });
      }
      this.toggleEdit();
    },

  },
  mounted() {
    if (this.value.name) {
      this.name = this.value.name;
      this.category = this.value.category;
      this.color = this.value.color;
    } else {
      this.edit = true;
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .elem-bubble{
    margin-bottom: 1vh;
  }
  .display-bubble{
    padding: 0.5vh 1vh;
  }
  .link{
    cursor: pointer;
    font-size: $fnt-12;
    margin-right: 2vh;
    &:last-child{
      margin-right: 0;
    }
    &.delete{
      color: red;
    }
    &:hover{
      text-decoration: underline;
    }
  }
  .cont-edit{
    display: flex;
    flex-direction: row;
  }
  .bubble{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &.edit{
      flex-wrap: wrap;
      .category{
        width: 28%;
      }
      .supp{
        width: 30%;
      }
      .cont-color-selector{
        width: 25%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        .color{
          width: calc(100% - 4vh);
        }
        .selectedcolor{
          width: 3.5vh;
          height: 3.5vh;
          margin-right: 0.5vh;
        }
      }
    }
  }
</style>
