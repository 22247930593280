<template>
  <div class="component-fullwidth" style="height: 100%;">
    <div class="flex-container flex-full no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page">
          Job Management
          <div class="other">
            <div class="cont-new-job-button">
              <div
                class="btn-green radius"
                @click="createNewOffer"
              >
                Add a New Job Offer
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="elem-flex contentbox box-shadow offer-cont">

      <div id="list-offer">
        <OfferList :selectable="true"></OfferList>
      </div>
    </div>
    <div class="inner-container">
      <div class="flex-container" v-if="offerEdit">
        <div class="contentbox sub-offer form">
          <OfferForm @endEdit="() => {offerEdit = false}"></OfferForm>
        </div>
        <div style="flex: 1" />
        <div class="sub-offer resume">
          <div class="prev-title wother">
            Preview
            <div
              class="btn-green small radius-small"
              v-if="selected"
              @click="seeOfferPage"
            >
              See Offer Page
            </div>
          </div>
          <div class="sub-sub-offer radius">
            <OfferResume></OfferResume>
          </div>
        </div>
      </div>
      <div class="flex-container" v-else-if="this.applicationOffer ">
        <offer-display v-if="offerLoaded" />
        <loading class="radius contentbox marginB box-shadow" v-else/>
        <div class="edit-button-cont">
          <button class="btn-green radius-small small" @click="() => {offerEdit = true;}">
            Edit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import OfferList from '@/views/Employee/EmployeeDashboard/OfferList.vue';
import OfferForm from '@/views/Employee/offerForm.vue';
import OfferResume from '@/views/Employee/offerResume.vue';
import OfferDisplay from '@/components/UniversalComponents/Offer/OfferDisplay.vue';
import Loading from '@/components/utilityPartials/_loading.vue';

export default {
  name: 'JobManagement',
  components: {
    OfferList,
    OfferForm,
    OfferResume,
    OfferDisplay,
    Loading,
  },
  data() {
    return {
      offerEdit: false,
      offerLoaded: false,
    };
  },
  computed: {
    ...mapState('company', {
      companyInfo: (state) => state.companyInfo,
      loading: (state) => state.loadingInfo,
    }),
    selected() {
      return this.$store.getters['company/selectedOffer'];
    },
    applicationOffer() {
      return this.$store.getters['company/currentOfferApplication']._id;
    },
  },
  mounted() {
    this.$store.dispatch('company/getInfo');
    if (this.applicationOffer) {
      this.setOffer(this.applicationOffer);
    }
  },
  methods: {
    createNewOffer() {
      this.$store.dispatch('company/setNewOfferApplication');
      this.offerEdit = true;
      this.offerLoaded = true;
    },
    seeOfferPage() {
      window.open(`${window.location.origin}/offer/${this.applicationOffer}`, '_blank');
    },
    setOffer(id) {
      this.$store.dispatch('offer/getOffer', id).then(() => { this.offerLoaded = true; });
    },
  },
  watch: {
    applicationOffer() {
      this.offerLoaded = false;
      this.setOffer(this.applicationOffer);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.offer-cont{
    margin-bottom: 3vh;

    background-color: $text-color;
}
.title-page{
  margin-bottom: 1.5vh;
}
.cont-new-job-button{
    display: flex;
    justify-content: flex-end;
  }
  .full{
    height: 100%;
  }
.sub-offer{
  padding: 1vh;
  height: calc(100% - 2vh);
  &.form{
    background-color: #F0F0F0;
    flex: 10.2;
  }
  &.resume{
    flex: 4;
    display: flex;
    flex-direction: column;
  }
  .sub-sub-offer{
    background-color: #F0F0F0;
    padding: 1vh;
  }
}
.prev-title{
  font-size: 2vh;
  margin-bottom: 1vh;
  &.wother{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .other {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      > div {
        margin-right: 2vh;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

</style>
