var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"job-form flex-container flex-full no-padding no-margin",class:!this.selected?'no-selected-main':''},[(!_vm.loading && (_vm.selected || !this.new))?_c('div',{staticClass:"inner-job-form elem-flex flex-overflow-column"},[_c('div',{staticClass:"elem-flex flex-overflow-column",attrs:{"id":"listresume"}},[_c('div',{staticClass:"elem-resume name-offer"},[_vm._v(" "+_vm._s(_vm.offer.name)+" ")]),_c('div',{staticClass:"divider-line"}),_c('div',{attrs:{"id":"list-resume"}},[(_vm.offer.description)?_c('div',{staticClass:"elem-resume desc",domProps:{"innerHTML":_vm._s(_vm.offer.description)}}):_vm._e(),_c('div',{staticClass:"resume-info"},[_c('div',{staticClass:"elem-infosupp contract"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
              content: 'Job Type',
              delay: {
                show: 0,
                hide: 0,
              }
            }),expression:"{\n              content: 'Job Type',\n              delay: {\n                show: 0,\n                hide: 0,\n              }\n            }",modifiers:{"top-center":true}}],staticClass:"picto"},[_c('img',{attrs:{"src":require('@/assets/pictos/picto_contract_darkpurple.svg')}})]),_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(this.offer.contract)+" ")])]),_c('div',{staticClass:"elem-infosupp location"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
              content: 'Localization',
              delay: {
                show: 0,
                hide: 0,
              }
            }),expression:"{\n              content: 'Localization',\n              delay: {\n                show: 0,\n                hide: 0,\n              }\n            }",modifiers:{"top-center":true}}],staticClass:"picto"},[_c('img',{attrs:{"src":require('@/assets/pictos/picto_localisation_darkpurple.svg')}})]),_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(this.offer.location)+" ")])]),_c('div',{staticClass:"elem-infosupp location"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
              content: 'Remote Work',
              delay: {
                show: 0,
                hide: 0,
              }
            }),expression:"{\n              content: 'Remote Work',\n              delay: {\n                show: 0,\n                hide: 0,\n              }\n            }",modifiers:{"top-center":true}}],staticClass:"picto"},[_c('img',{staticClass:"small",attrs:{"src":require('@/assets/pictos/picto_remote.svg')}})]),_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(this.offer.remote)+" ")])]),_c('div',{staticClass:"elem-infosupp sector"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
              content: 'Field',
              delay: {
                show: 0,
                hide: 0,
              }
            }),expression:"{\n              content: 'Field',\n              delay: {\n                show: 0,\n                hide: 0,\n              }\n            }",modifiers:{"top-center":true}}],staticClass:"picto"},[_c('img',{staticClass:"small",attrs:{"src":require('@/assets/pictos/picto_sector.svg')}})]),_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(this.offer.sector)+" ")])]),(this.offer.salary.frequency != 'Unspecified')?_c('div',{staticClass:"elem-infosupp sector"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
              content: 'Salary',
              delay: {
                show: 0,
                hide: 0,
              }
            }),expression:"{\n              content: 'Salary',\n              delay: {\n                show: 0,\n                hide: 0,\n              }\n            }",modifiers:{"top-center":true}}],staticClass:"picto"},[_c('img',{staticClass:"small",attrs:{"src":require('@/assets/pictos/picto_sector.svg')}})]),_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(this.offer.salary.frequency)+" "),_c('br'),_vm._v(" "+_vm._s(this.salary)+" ")])]):_vm._e(),_c('div',{staticClass:"elem-infosupp sector"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
              content: 'Process',
              delay: {
                show: 0,
                hide: 0,
              }
            }),expression:"{\n              content: 'Process',\n              delay: {\n                show: 0,\n                hide: 0,\n              }\n            }",modifiers:{"top-center":true}}],staticClass:"picto"},[_c('img',{attrs:{"src":require('@/assets/pictos/picto_process_darkpurple.svg')}})]),_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(this.processName)+" ")])])])])])]):_vm._e(),(!this.selected && !this.new)?_c('div',[_c('h3',[_vm._v("Please Select an Offer")])]):_vm._e(),(!this.selected && this.new)?_c('div',[_c('h3',[_vm._v("No Informations")])]):_vm._e(),(this.loading && this.selected)?_c('loading'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }