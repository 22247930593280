<template>
  <div class="select-switch">
    <VueSlider
      v-model="tempValue"
      :min="dataRangeMin"
      :max="dataRangeMax"
      :interval="step"
      :enable-cross="false"
    />
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'inputRange',
  components: {
    VueSlider,
  },
  props: {
    dataRangeMin: Number,
    dataRangeMax: Number,
    step: Number,
    value: Object,
  },
  data() {
    return {
      tempValue: [this.value.min, this.value.max],
    };
  },
  computed: {
  },
  watch: {
    tempValue() {
      this.$emit('input', {
        min: this.tempValue[0],
        max: this.tempValue[1],
      });
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .select-switch{
    width: 98%;
    margin: 0 auto;
  }
</style>
