<template>
  <div class="inputNumber">
    <input
      class="v-select "
      type="number"
      v-model="tempValue"
      @input="checkvalue($event)"
    />
    <div class="controls">
      <div class="control-btn moinsbtn" @click="moinsvalue()">
        <span>-</span>
      </div>
      <div class="control-btn plusbtn" @click="plusvalue()">
        <span>+</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'inputNumber',
  props: {
    min: Number || 'undefined',
    max: Number,
    step: Number,
    data: Number,
  },

  data() {
    return {
      tempValue: this.data,
    };
  },
  computed: {
  },
  mounted() {
    this.$emit('input', this.tempValue);
  },
  updated() {
    this.$emit('input', this.tempValue);
  },
  methods: {
    plusvalue() {
      if (this.max !== 0) {
        if (parseInt(this.tempValue, 10) < this.max) {
          this.tempValue = parseInt(this.tempValue, 10) + this.step;
        }
      } else {
        this.tempValue = parseInt(this.tempValue, 10) + this.step;
      }
    },
    moinsvalue() {
      if (this.min !== 'undefined') {
        if (parseInt(this.tempValue, 10) > this.min) {
          this.tempValue = parseInt(this.tempValue, 10) - this.step;
        }
      } else {
        this.tempValue = parseInt(this.tempValue, 10) - this.step;
      }
    },
    checkvalue(e) {
      console.log('e : ', e.target.value);
      if (this.max !== 0) {
        if (e.target.value > this.max) {
          this.tempValue = this.max;
        }
      }
      if (this.min !== 0) {
        if (e.target.value < this.min) {
          this.tempValue = this.min;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.inputNumber{
  position: relative;
  .v-select{
    border-radius: 1vh;
    border: none;
    box-shadow: 0 0 0.5vh rgba(22, 0, 51, 0.15);
  }
  input{
    padding-left: 2.5vh;
    margin-top: 0 !important;
  }
  .controls{
    width: 7vh;
    position: absolute;
    top: 50%;
    right: 2vh;
    transform: translateY(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .control-btn{
      font-size: $fnt-subtitle !important;
      text-decoration: none !important;
      color: $text_color;
      font-weight: 900;
      height: 3vh;
      width: 3vh;
      background-color: $green_color;
      border-radius: 50%;
      margin-right: 1vh;
      position: relative;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      &:hover{
        background-color: $text_color;
        color: $green_color !important;
        span{
        color: $green_color !important;
        }
      }
      span{
        pointer-events: none;
        user-select: none;
        text-decoration: none !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-45%) translateX(-50%);
      }
      &.moinsbtn{
        span{
          transform: translateY(-50%) translateX(-50%);
        }
      }
    }
  }
}
</style>
