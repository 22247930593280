<template>
<div class="job-form flex-container flex-full no-padding no-margin"
  :class="!this.selected?'no-selected-main':''"
>
  <div
    class="inner-job-form elem-flex flex-overflow-column"
    v-if="!loading && (selected || !this.new)"
  >
    <div id="listresume" class="elem-flex flex-overflow-column">
      <div class="elem-resume name-offer">
        {{offer.name}}
      </div>
      <div class="divider-line" />
      <div class="" id="list-resume">
        <div class="elem-resume desc" v-if="offer.description" v-html="offer.description"></div>
        <div class="resume-info">
          <div class="elem-infosupp contract">
          <div class="picto"
            v-tooltip.top-center="{
              content: 'Job Type',
              delay: {
                show: 0,
                hide: 0,
              }
            }"
          >
            <img :src="require('@/assets/pictos/picto_contract_darkpurple.svg')">
          </div>
          <div class="txt">
            {{this.offer.contract}}
          </div>
        </div>
        <div class="elem-infosupp location">
          <div class="picto"
            v-tooltip.top-center="{
              content: 'Localization',
              delay: {
                show: 0,
                hide: 0,
              }
            }"
          >
            <img :src="require('@/assets/pictos/picto_localisation_darkpurple.svg')">
          </div>
          <div class="txt">
            {{this.offer.location}}
          </div>
        </div>
        <div class="elem-infosupp location">
          <div class="picto"
            v-tooltip.top-center="{
              content: 'Remote Work',
              delay: {
                show: 0,
                hide: 0,
              }
            }"
          >
            <img class="small" :src="require('@/assets/pictos/picto_remote.svg')">
          </div>
          <div class="txt">
            {{this.offer.remote}}
          </div>
        </div>
        <div class="elem-infosupp sector">
          <div class="picto"
            v-tooltip.top-center="{
              content: 'Field',
              delay: {
                show: 0,
                hide: 0,
              }
            }"
          >
            <img class="small" :src="require('@/assets/pictos/picto_sector.svg')">
          </div>
          <div class="txt">
            {{this.offer.sector}}
          </div>
        </div>
        <div class="elem-infosupp sector" v-if="this.offer.salary.frequency != 'Unspecified'">
          <div class="picto"
            v-tooltip.top-center="{
              content: 'Salary',
              delay: {
                show: 0,
                hide: 0,
              }
            }"
          >
            <img class="small" :src="require('@/assets/pictos/picto_sector.svg')">
          </div>
          <div class="txt">
            {{this.offer.salary.frequency}} <br>
            {{this.salary}}
          </div>
        </div>
        <div class="elem-infosupp sector">
          <div class="picto"
            v-tooltip.top-center="{
              content: 'Process',
              delay: {
                show: 0,
                hide: 0,
              }
            }"
          >
            <img  :src="require('@/assets/pictos/picto_process_darkpurple.svg')">
          </div>
          <div class="txt">
            {{this.processName}}
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!this.selected && !this.new">
    <h3>Please Select an Offer</h3>
  </div>
  <div v-if="!this.selected && this.new">
    <h3>No Informations</h3>
  </div>
  <loading v-if="this.loading && this.selected" />
</div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import Loading from '@/components/utilityPartials/_loading.vue';
import validateMixin from '@/modules/mixins/validate';

const blankOffer = () => ({
  name: '',
  title: '',
  description: '',
  contract: '',
  location: '',
  remote: 'No',
  remoteFrequency: '',
  sector: '',
  requirements: [],
  desiredProfileDescription: '',
  desiredLevelOfStudy: '',
  expectedExperience: '',
  projectPresentation: '',
  teamPresentation: '',
  image: '',
  // Query Attributes
  startDate: '',
  salary: {
    frequency: '',
    currency: '',
    amount: 0,
    range: { min: 0, max: 150000 },
    salaryType: '',
  },
  process: '',
  // Process Attributes
  diffusion: 'Platform',
  displayApplicantNumber: false,
  criteriaList: [],

  applicationRequirements: {
    motivationLetter: 'Optional',
    resumeFile: 'Optional',
    resumeProfile: 'Optional',
    portfolio: 'Optional',
    profileCompletion: {
      requirement: 'Optional',
      percentComplete: 100,
    },
    countryPermission: {
      requirement: false,
      country: '',
    },
  },
});
export default {
  name: 'OfferResume',
  components: {
    Loading,
  },
  mixins: [validateMixin],
  computed: {
    offerId() {
      return this.$store.state.company.currentOfferApplicationId;
    },
    selected() {
      return this.$store.getters['company/selectedOffer'];
    },
    stateOffer() {
      return this.$store.getters['company/getOfferById'](this.offerId);
    },
    new() {
      return this.$store.state.company.currentOfferApplicationId === 'new';
    },
    companyProcesses() {
      return this.$store.getters['company/processes'].map((process) => ({
        name: process.name,
        value: process._id,
      }));
    },
    salary() {
      const salString = this.offer.salary.currency || '$';
      if (this.offer.salary.amount) {
        return `${salString}${this.offer.salary.amount}`;
      }

      return `${salString}${this.offer.salary.range.min}-${this.offer.salary.range.max}`;
    },
    processName() {
      const name = this.$store.getters['company/processName'](this.offer.process);
      if (name) {
        return name;
      }
      return 'Custom';
    },
  },
  data() {
    return {
      loading: true,
      sending: false,
      // Offer option value
      offer: blankOffer(),
    };
  },
  mounted() {
    if (this.selected) {
      this.loadOffer();
      setTimeout(() => {
        this.startScroll();
      }, 500);
    } else {
      this.loading = false;
    }
  },
  methods: {
    loadOffer() {
      this.loading = true;

      this.offer = { ...this.offer, ...this.stateOffer };
      this.loading = false;
    },
    startScroll() {
      const ps = new PerfectScrollbar('#list-resume', {
        wheelPropagation: false,
        suppressScrollX: true,
      });
      ps.update();
    },
  },
  watch: {
    offerId(newValue) {
      this.offer = blankOffer();
      if (newValue !== 'new') {
        this.loadOffer();
      }
      setTimeout(() => {
        this.startScroll();
      }, 500);
    },
    stateOffer() {
      this.loadOffer();
    },
  },
  beforeDestroy() {
    this.$store.dispatch('company/clearCurrentOfferNew');
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .job-form{
    height: 100%;
    // min-height: 20vh;
    &.no-selected-main{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .divider-line{
      width: 100%;
      background-color: black;
      height: 0.1vh;
      margin-bottom: 1vh;
    }
  }
  #listresume{
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  #list-resume{
    max-height: 43vh;
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .elem-resume{
    margin-bottom: 1vh;
    width: calc(100% - 1vh);
    word-break: break-word;
    &.name-offer{
      font-family: 'MontserratBold';
      font-weight: 600;
      font-size: 2vh;
      color: #8c28e1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      word-break: break-word;
    }
    &.desc{
      font-size: $fnt-14;
      margin-top: 1vh;
      margin-bottom: 2vh;
      width: 100%;
    }
  }
  .resume-info{
    display: flex;
    width: calc(100% - 1vh);
    background-color: #FFF;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    // height: 15vh;
    position: relative;
    .elem-infosupp{
      position: relative;
      margin-right: 1vh;
      display: flex;
      flex-direction: row;
      width: 40%;
      margin:1vh .5vh;
      .picto{
        position: relative;
        width: 1vw;
        font-size: 0;
        margin-right: 0.5vh;
        img{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 150%;
        }
          .small{
            width: 100% !important ;
          }
      }
      .txt{
        font-size: 1.2vh;
        line-height: 1.1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
</style>
